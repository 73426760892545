<template>
	<div class="app-container">
		<!-- filter -->
		<div class="filter-container">
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<label class="label">关键字: </label>
				<el-input v-model="searchKey" style='width:200px' placeholder="活动名称"></el-input>
			</div>
			<!-- 活动状态 -->
			<div class="filter-item">
				<label class="label">活动状态: </label>
				<el-select v-model="status" placeholder="请选择">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in statusOptions"	:key="item.value" :label="item.label"	:value="item.value">
					</el-option>
				</el-select>
			</div>
			<!-- button -->
			<div class="filter-item">
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="handleFilter">查询</el-button>
				<button-permissions :datas="'addCashBack'">
					<el-button type="primary" style="margin-left:30px;width:100px;" @click="handleAddCoupon">添加</el-button> 
				</button-permissions>
			</div>
		</div>
		<!-- table -->
		<div class="table-container">
			<el-table	:data="tableData"	style="width: 100%" v-loading='loading'>
				<el-table-column	prop="Name"	label="活动名称"></el-table-column>
				<el-table-column width="180px"	label="活动时间">
					<template slot-scope="scope">
						{{scope.row.StartTime}}
						<span style="margin: 0 5px;">至</span>
						{{scope.row.EndTime}}
					</template>
				</el-table-column>
				<el-table-column	label="返现区间">
					<template slot-scope="scope">
						<div v-if="scope.row.IsRandom">
							{{scope.row.CashbackStart}}%
							<span style="margin: 0 5px;">~</span>
							{{scope.row.CashbackEnd}}%
						</div>
						<div v-else>
							{{scope.row.CashbackStart}}%
						</div>
					</template>
				</el-table-column>
				<el-table-column	prop="ActivityState"	label="状态">
					<template slot-scope="scope">
						{{statusType[scope.row.ActivityState]}}
					</template>
				</el-table-column>
				<el-table-column	prop="SumCashbackMoney"	label="累计返现"></el-table-column>
				<el-table-column	label="支付订单">
					<template slot-scope="scope">
						<span v-if="scope.row.PayCount==0">0</span>
						<el-button v-else type="text" @click="handleRouteJump(scope.row)">{{scope.row.PayCount}}</el-button>
						<!-- <div class="table-button" @click="handleRouteJump(scope.row.Id)">{{scope.row.PayCount}}</div> -->
					</template>
				</el-table-column>
				<el-table-column	prop="BuyerNum"	label="支付人数"></el-table-column>
				<el-table-column	label="支付金额">
					<template slot-scope="scope">
						<span>{{+scope.row.PayAmount}}</span>
					</template>
				</el-table-column>
				<el-table-column	label="客单价">
					<template slot-scope="scope">
						<span>{{+scope.row.BuyerUnitPrice}}</span>
					</template>
				</el-table-column>
				<el-table-column	label="笔单价">
					<template slot-scope="scope">
						<span>{{+scope.row.PayUnitPrice}}</span>
					</template>
				</el-table-column>
				<el-table-column	prop="AddTime"  width="150"	label="创建时间"></el-table-column>
				<el-table-column	label="操作" width="200px">
					<template slot-scope="scope">
						<div v-if="scope.row.ActivityState==2">
							<div class="table-button" @click="handleTableEdit(scope.row)">查看</div>
							<button-permissions :datas="'copyCashBack'">
								<div class="table-button"  @click="handleTableCopy(scope.row)">复制</div>
							</button-permissions>

							<button-permissions :datas="'deleteCashBack'">
								<div class="table-button" style="color:#F56C6C" @click="handleTableDelete(scope.row.Id)">删除</div>
							</button-permissions>
							<!-- <div class="table-button"  @click="handleTableCopy(scope.row)" v-if="butttonShow.copyCashBack">复制</div>
							<div class="table-button" style="color:#F56C6C" @click="handleTableDelete(scope.row.Id)" v-if="butttonShow.deleteCashBack">删除</div> -->
						</div>
						<div v-else>
							<button-permissions :datas="'editCashBack'">
								<div class="table-button" @click="handleTableEdit(scope.row)" >编辑</div>
							</button-permissions>

							<button-permissions :datas="'cancelCashBack'">
								<div class="table-button" @click="handleTableFail(scope.row.Id)" >使终止</div>
							</button-permissions>

							<button-permissions :datas="'copyCashBack'">
								<div class="table-button" @click="handleTableCopy(scope.row)" >复制</div>
							</button-permissions>
							<!-- <div class="table-button" @click="handleTableEdit(scope.row)" v-if="butttonShow.editCashBack">编辑</div>
							<div class="table-button" @click="handleTableFail(scope.row.Id)" v-if="butttonShow.cancelCashBack">使终止</div>
							<div class="table-button" @click="handleTableCopy(scope.row)" v-if="butttonShow.copyCashBack">复制</div> -->
						</div>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination v-if="page.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="page.current" :page-sizes="[10, 20, 30, 40, 50]" :page-size="page.size" layout="total, sizes, prev, pager, next, jumper"
			 :total="page.total">
			</el-pagination>
		</div>
	</div>
</template>

<script>	
import apiList from '@/api/other.js'
import buttonPermissions from '@/components/buttonPermissions';
	export default{
		components: {
			buttonPermissions
		},
		data(){
			return{
				loading:false,
				statusType:['未开始','进行中','已结束'],
				tableData:[
					{
						name:'sss',
						id:11
					}
				],
				searchKey:'',
				status:null,
				statusOptions:[
					{
						label:'未开始',
						value:0
					},
					{
						label:'进行中',
						value:1,
					},
					{
						label:'已结束',
						value:2
					}
				],
				tableData:[
					{
						name:'sss',
						id:11
					}
				],
				page:{
					total:0,
					current:1,
					size:20
				},
			}
		},
		created(){
			this.getList()
		},
    methods: {
				// 获取列表数据
			async getList() {
				this.loading = true;
				try {
					let data = {
						KeyWords: this.searchKey, // 搜索关键字
						Skip: (this.page.current - 1) * this.page.size, 
						Take: this.page.size, 
						type: this.type,
						ActivityState:this.status
					}
					let result = await apiList.activitycashbackIndex(data);

					this.page.total = result.Result.Total;
					this.tableData = result.Result.Results || [];
					this.tableData.forEach(t=>{
						t.PayAmount = t.PayAmount.toFixed(2)
						t.PayUnitPrice = t.PayUnitPrice.toFixed(2)
						t.BuyerUnitPrice = t.BuyerUnitPrice.toFixed(2)
					})

				} catch (error) {
					console.log(error);
					this.loading = false
				} finally {
					this.loading = false;
				}
			},
			handleFilter(){
				this.getList()
			},
			//复制
			handleTableCopy(val){
				this.$router.push({ path: '/market/orderCashBack/editCashDetaile',query:{copyId:val.Id}})
			},
			handleAddCoupon(){
        this.$router.push({ path:'/market/orderCashBack/editCashDetaile'})
			},
			//支付订单跳转
			handleRouteJump(val){
				this.$router.push({
					path:'/order/orderMange',
					query:{
						ActivityId : val.Id,
						ActivityType : 6,
						starTime :  val.StartTime,
						endTime : val.EndTime,
					}
				})
			},
				// table编辑，查看
			handleTableEdit(val){
				// status：:0：未开始，所有的都可以编辑，1：进行中，不可编辑开始时间，2：已结束，查看，所有的都不能编辑
				this.$router.push({ path: '/market/orderCashBack/editCashDetaile',query:{id:val.Id,status:val.ActivityState}})
			},
				// table使失效
			handleTableFail(id){
				this.$confirm('活动终止后，你将不可再对这场活动进行编辑，是否确认使失效？', '提示', {
					confirmButtonText: '确认失效',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(async() => {
					let result = await apiList.activitycashbackClose({
						Id: id
					})
					this.$message({showClose: true,
						type: 'success',
						message: '失效成功!'
					});
					this.getList()

				}).catch(() => {
					this.$message({showClose: true,
						type: 'info',
						message: '已取消失效'
					});
				}).finally(() => {
				})
			},
				// table删除
			handleTableDelete(id){
				this.$confirm('是否确认删除活动？删除后不可恢复', '提示', {
					confirmButtonText: '确认删除',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(async() => {
					let result = await apiList.activitycashbackDelete({
						Id: id
					})
					this.$message({showClose: true,
						type: 'success',
						message: '删除成功!'
					});
					this.getList()

				}).catch(() => {
					this.$message({showClose: true,
						type: 'info',
						message: '已取消删除'
					});
				}).finally(() => {
				})
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.page.size = val;
				this.getList();
			},
			// 翻页
			handleCurrentChange(val) {
				this.page.current = val;
				this.getList();
			},
    }
	}
</script>

<style  lang="less" scoped>
.app-container{
	background-color: #fff;
}
	.table-button{
		color: #409EFF;
		display: inline-block;
		margin-right: 20px;
		cursor: pointer;
	}
	.dialog{
		width: 100%;
		/deep/.el-dialog__header {
				border-bottom: 1px solid #ddd;
		}
		.content{
			padding: 0 30px;
			display: flex;
			flex-direction: row;
			.right{
				margin-left: 20px;
			}
			.text{
				margin-left: 20px;
				font-size: 14px;
				color: #999;
				line-height: 40px;
			}
		}
		.button{
			margin: 0 auto;
			width: 200px;
		}
	}
	.data-dialog{
		.value{
			line-height: 50px;
			font-size: 24px;
			color: #000;
		}
		.item{
			color: #666;
			margin-top: 10px;
		}
	}

</style>
